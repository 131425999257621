<template>
  <div class="container">
    <v-row class="">
      <v-col cols="12" class="top-panel">
        <div class="add-btn" @click="createItem">
          <img src="../assets/img/plusAdd.svg" alt="pluss" />
          <p class="add-text">Добавить</p>
        </div>
        <div class="vertical-line"></div>
      </v-col>
    </v-row>

    <div id="manufacturer">
      <v-container class="secondary mt-5">
        <v-row>
          <v-col>
            <v-data-table :headers="headers" :items="filteredStaffs" hide-default-footer>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="page-title">Персонал
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>

                  <v-dialog v-model="dialogCreate" max-width="537px">
                    <v-card>
                      <v-card-title class="modal-title">
                        {{
          isEditing
            ? "Редактирование персонала"
            : "Добавление персонала"
        }}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <div class="py-0 px-0">
                          <div class="col-content px-0 py-0 pb-0">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Имя пользователя</p>
                            </v-col>
                            <v-col cols="1" class="py-0 red-dot-margin-2">
                              <span class="red-dot">*</span>
                            </v-col>

                            <v-col cols="8" class="col-paddings-r-l px-0 py-0">
                              <textarea class="col-description textarea-pt" v-model="userFullName"
                                ref="userFullNameTextarea" :placeholder="isuserFullNameFocused
          ? ''
          : textareaPlaceholder
          " @focus="isuserFullNameFocused = true" @blur="isuserFullNameFocused = false"></textarea>
                            </v-col>
                          </div>
                        </div>
                        <div class="horizontal-line"></div>
                        <div class="py-0 px-0">
                          <div class="col-content px-0 py-0 pb-0">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Логин</p>
                            </v-col>

                            <v-col cols="1" class="py-0 red-dot-margin-2">
                              <span class="red-dot">*</span>
                            </v-col>

                            <v-col cols="8" class="col-paddings-r-l px-0 py-0">
                              <textarea class="col-description textarea-pt" v-model="userName" ref="userNameTextarea"
                                :placeholder="isuserNameFocused ? '' : textareaPlaceholder
          " @focus="isuserNameFocused = true" @blur="isuserNameFocused = false"></textarea>
                            </v-col>
                          </div>
                        </div>
                        <div class="horizontal-line"></div>
                        <div class="py-0 px-0">
                          <div class="col-content px-0 py-0 pb-0">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Пароль</p>
                            </v-col>

                            <v-col cols="1" class="py-0 red-dot-margin-2">
                              <span class="red-dot">*</span>
                            </v-col>

                            <v-col cols="8" class="col-paddings-r-l px-0 py-0">
                              <textarea class="col-description textarea-pt" v-model="userPassword"
                                :disabled="isEditing == true" ref="userPasswordTextarea" :placeholder="isuserPasswordFocused
          ? ''
          : textareaPlaceholder
          " @focus="isuserPasswordFocused = true" @blur="isuserPasswordFocused = false"></textarea>
                            </v-col>
                          </div>
                        </div>
                        <div class="horizontal-line"></div>
                      </v-card-text>
                      <div :class="isEditing === true
          ? 'btn-container'
          : 'btn-container-1 px-6'
          ">
                        <div v-if="isEditing" :class="currentState === 'Active'
          ? 'save-btn-second'
          : 'delete-btn'
          " text @click="setUserState">
                          {{
          currentState === "Active"
            ? "Активировать"
            : "Деактивировать"
        }}
                        </div>
                        <div class="cancel-btn" text @click="closeCreate">
                          Отмена
                        </div>

                        <div :class="isEditing === true ? 'save-btn' : 'save-btn ml-7'
          " text @click="handleButtonClick">
                          Сохранить
                        </div>
                      </div>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="780px">
                    <v-card>
                      <v-card-title class="warning-title">{{
          currentState === "Active"
            ? "Вы точно хотите активировать данного сотрудника?"
            : "Вы точно хотите деактивировать данного сотрудника?"
        }}
                        <v-btn icon @click="closeDelete">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-actions class="warning-margin">
                        <div class="cancel-btn" text @click="closeDelete">
                          Отмена
                        </div>
                        <div :class="currentState === 'Active'
          ? 'save-btn-paswd'
          : 'del-btn'
          " text @click="setUserState">
                          {{
          currentState === "Active"
            ? "Активировать"
            : "Деактивировать"
        }}
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogChangePassword" max-width="570px">
                    <v-card>
                      <v-card-title class="text-h5">Cброс Пароля
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeChangePassword">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <div class="py-0 px-0">
                          <div class="col-content px-0 py-0 pb-0">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Новый пароль</p>
                            </v-col>
                            <v-col cols="8" class="col-paddings-r-l px-0 py-0">
                              <textarea class="col-description textarea-pt" v-model="newUserPassword" :placeholder="isNewUserPassword ? '' : textareaPlaceholder
          " @focus="isNewUserPassword = true" @blur="isNewUserPassword = false"></textarea>
                            </v-col>
                          </div>
                        </div>
                        <div class="horizontal-line"></div>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <div class="cancel-btn" text @click="closeChangePassword">
                          Отмена
                        </div>
                        <div class="save-btn-paswd" text @click="setNewPassword()">
                          Сохранить
                        </div>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                <v-snackbar v-model="snackbar" :timeout="3000" class="btn-contaiener">
                  {{ message }}
                  <template v-slot:action="{ attrs }">
                    <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
                      Закрыть
                    </div>
                  </template>
                </v-snackbar>
              </template>
              <template v-slot:item="{ item, index }">
                <tr style="cursor: pointer">
                  <td @click="getUser(item)">
                    {{ item.fullName || " " }}
                  </td>

                  <td @click="getUser(item)">
                    {{ item.createdOn | formatDate("dd.MM.yyyy") }}
                  </td>
                  <td @click="getUser(item)">
                    {{ item.modifiedOn | formatDate("dd.MM.yyyy") }}
                  </td>
                  <td @click="getUser(item)">
                    {{ getStatusTranslation(item.stateCode) }}
                    <!-- <span v-for="(localization, index) in item.localizations" :key="index">
                        {{ localization.locale }}: {{ localization.name }}<br>
                    </span> -->
                  </td>

                  <td style="text-align: right">
                    <v-icon medium @click="toggleActionMenu(index, $event)">mdi-dots-vertical</v-icon>
                    <div class="ActionMenu" v-if="showActionMenu[index]" :ref="'ActionMenu' + index">
                      <div @click="getUser(item)" class="ActionMenu-item-1">
                        Редактировать
                      </div>
                      <div class="divider-modal"></div>
                      <div @click="deleteItem(item)" class="ActionMenu-item-2">
                        {{
                        item.stateCode === "Active"
                        ? "Деактивировать"
                        : "Активировать"
                        }}
                      </div>
                      <div class="divider-modal"></div>
                      <div @click="changePaswd(item)" class="ActionMenu-item-2">
                        Сброс Пароля
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
export default {
  directives: { mask },
  name: "Staff",

  data: () => ({
    showActionMenu: [],
    isHovered: false,
    isHoveredTogo: false,
    showInputMenu: false,
    selectedOption: "",
    isPlaceholderHidden: false,
    iscategoryNameFocused: false,
    iscategoryNameEnFocused: false,
    userName: "",
    isuserNameFocused: false,
    userPassword: "",
    isuserPasswordFocused: false,
    userFullName: "",
    isuserFullNameFocused: false,
    selectedRoles: null,
    categoryName: "",
    categoryNameEn: "",
    orderNum: "",
    dialogDelete: false,
    dialogCreate: false,
    dialogChangePassword: false,
    newUserPassword: "",
    isNewUserPassword: false,
    currentState: "",
    search: "",
    snackbar: false,
    message: "",
    statusTranslations: {
      Active: "Активный",
      Inactive: "Неактивный",
    },
    curUser: {},
    staffs: {},
    selectedType: null,
    headers: [
      {
        text: "Имя пользователя",
        align: "start",
        value: "fullName",
      },
      { text: "Дата создания", value: "createdOn" },
      { text: "Дата изменения", value: "modifiedOn" },
      { text: "Статус", value: "stateCode" },
      { text: "Действия", align: "end", value: "actions", sortable: false },
    ],
    profiles: [],
    newUser: {},
    error: null,
    isEditing: false,
    currentCategory: "",
  }),

  created() {
    this.initialize();
    // this.profileInfo();
  },
  computed: {
    filteredStaffs() {
      // Фильтруем данные внутри таблицы в зависимости от выбранного типа
      if (this.selectedType) {
        console.log(this.selectedType);
        return this.staffs.items
          .filter
          // (item) => item.rolesTest === this.selectedType
          ();
      } else {
        return this.staffs.items;
      }
    },

    ...mapGetters({
      user: "user",
    }),
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },
  },

  mounted() {
    window.addEventListener("click", this.handleClickOutside);
    window.addEventListener("click", this.handleClickOutsideTogo);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("click", this.handleClickOutsideTogo);
  },

  methods: {
    toggleActionMenu(index, event) {
      event.stopPropagation(); // Остановить распространение события, чтобы оно не закрыло меню
      // Закрыть все другие меню
      for (let i = 0; i < this.showActionMenu.length; i++) {
        if (i !== index) {
          this.$set(this.showActionMenu, i, false);
        }
      }
      // Переключить меню для выбранного элемента
      this.$set(this.showActionMenu, index, !this.showActionMenu[index]);

      // Добавляем обработчик события на документе для закрытия меню при клике вне меню
      if (this.showActionMenu[index]) {
        this.$nextTick(() => {
          document.addEventListener("click", this.handleClickOutside);
        });
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    handleClickOutside(event) {
      let clickedOutside = true;
      if (this.items && this.items.length > 0) {
        for (let i = 0; i < this.items.length; i++) {
          const menuRef = this.$refs["ActionMenu" + i];
          if (menuRef && menuRef.contains(event.target)) {
            clickedOutside = false;
            break;
          }
        }
      }
      if (clickedOutside) {
        for (let i = 0; i < this.showActionMenu.length; i++) {
          this.$set(this.showActionMenu, i, false);
        }
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    getStatusTranslation(status) {
      return this.statusTranslations[status] || status;
    },
    getEnglishRoleTranslation(role) {
      for (const key in this.roleTranslations) {
        if (Object.prototype.hasOwnProperty.call(this.roleTranslations, key)) {
          if (this.roleTranslations[key] === role) {
            return key;
          }
        }
      }
      return role;
    },
    hidePlaceholder(inputName) {
      if (inputName === "categoryName") {
        this.iscategoryNameFocused = true;
      } else if (inputName === "categoryNameEn") {
        this.iscategoryNameEnFocused = true;
      }
    },

    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      // this.activeTextarea = null;
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    closeDialog() {
      this.dialogCreate = false;
      this.userName = "";
      this.userFullName = "";
      this.userPassword = "";
      this.isEditing = false;
    },
    handleButtonClick() {
      if (this.isEditing) {
        // Выполните метод для редактирования
        this.editUser();
      } else {
        // Выполните метод для создания нового приложения
        this.createNewUser();
      }
    },
    initialize() {
      this.$http
        .put("/api/User/PagedList", {
          pageNumber: this.currentPage,
          pageSize: this.itemsPerPage,
          //   filters: [
          //     {
          //       property: "RestaurantId",
          //       operator: "eq",
          //       value: this.user.currentRest.restaurant.id,
          //     },
          //   ],
          sorting: {
            property: "CreatedOn",
            isDescending: true
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.staffs = response.data;
          this.totalPages = response.data.totalPages; // Общее количество страниц
          this.totalItems = response.data.totalCount; // Общее количество элементов
          console.log(this.totalItems);
        })
        .catch((error) => {
          this.error = error;
          console.log(error);
        });
    },

    deleteItem(item) {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.editedIndex = this.filteredStaffs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.currentCategory = item.id;
      this.currentState = item.stateCode === "Active" ? "Inactive" : "Active";
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete(`/api/Employee/${this.editedItem.id}`)
        .then(() => {
          this.staffs.items.splice(this.editedIndex, 1);
          this.closeDelete();

          // Здесь мы устанавливаем флаг showSnackbar в true,
          // чтобы уведомление показалось после завершения удаления
          this.snackbar = true;
          this.message = "Сотрудник успешно удален";
        })
        .catch((error) => {
          alert(error);
          this.snackbar = true;
          this.message = "Ошибка удаления сотрудника";
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    createItem() {
      this.dialogCreate = true;
      this.userPassword = "";
      this.userFullName = "";
      this.userName = "";
      this.isEditing = false;
      // this.getEmployee();
    },
    changePaswd(item) {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.editedIndex = this.filteredStaffs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.currentCategory = item.id;
      this.dialogChangePassword = true;
    },
    closeCreate() {
      this.dialogCreate = false;
      this.userPassword = "";
      this.userFullName = "";
      this.userName = "";
      this.isEditing = false;
    },
    closeChangePassword() {
      this.dialogChangePassword = false;
      this.newUserPassword = "";
    },
    createNewUser() {
      //   this.newEmp.employeeRole = this.getEnglishRoleTranslation(
      //     this.selectedOption
      //   );
      if (!this.userFullName == null || this.userFullName.trim() === '') {
        this.snackbar = true;
        this.message = "Имя пользователя обязателено для заполнения";
        return;
      }
      else {
        this.newUser.fullName = this.userFullName;
      };

      if (!this.userName == null || this.userName.trim() === '') {
        this.snackbar = true;
        this.message = "Логин пользователя обязателен для заполнения";
        return;
      }
      else {
        this.newUser.name = this.userName;
      };


      if (!this.userPassword == null || this.userPassword.trim() === '') {
        this.snackbar = true;
        this.message = "Пароль пользователя обязателен для заполнения";
        return;
      }
      else {
        this.newUser.password = this.userPassword;
      };
      (this.newUser.name = this.userName),
        (this.newUser.password = this.userPassword),
        (this.newUser.fullName = this.userFullName),
        // (this.newEmp.mobilePhone = this.phone),

        this.$http
          .post("/api/User", this.newUser)
          .then(() => {
            //this.initialize();
            this.dialogCreate = false;
            this.newUser = {
              name: "",
              password: "",
              fullName: "",
              isAdmin: false,
              //userFullName: "",
            };
            this.snackbar = true;
            this.message = "Пользователь успешно добавлен";
            this.initialize();
          })
          .catch((error) => {
            this.error = error;
            this.snackbar = true;
            this.message = "Ошибка добавления пользователя";
          });
    },
    getUser(item) {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.createItem();
      this.isEditing = true;
      this.editedIndex = this.filteredStaffs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.currentCategory = item.id;
      this.currentState = item.stateCode === "Active" ? "Inactive" : "Active";

      this.$http
        .get(`/api/User/${item.id}`)
        .then((response) => {
          this.curUser = response.data;
          (this.userName = this.curUser.name),
            (this.userPassword = "Пароль Скрыт"),
            (this.userFullName = this.curUser.fullName);
        })
        .catch((error) => {
          alert(error);
        });
    },
    setUserState() {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.$http
        .put(`/api/User/SetState/${this.currentCategory}`, null, {
          params: {
            stateCode: this.currentState,
          },
        })
        .then((response) => {
          this.curUser = response.data;
          this.dialogCreate = false;
          this.initialize();
          this.closeDelete();
          this.snackbar = true;
          this.message = "Действие прошло успешно";
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.message = "Ошибка выполнения";
        });
    },
    setNewPassword() {

      if (!this.newUserPassword == null || this.newUserPassword.trim() === '') {
        this.snackbar = true;
        this.message = "Введите новый пароль";
        return;
      }


      this.showActionMenu = this.showActionMenu.map(() => false);
      this.$http
        .put(`/api/User/ChanagePassword/${this.currentCategory}`, {
          newPassword: this.newUserPassword,
        })
        .then((response) => {
          this.curUser = response.data;
          this.initialize();
          this.closeChangePassword();
          this.snackbar = true;
          this.message = "Пароль успешно сброшен";
        })
        .catch((error) => {
          alert(error);
          this.snackbar = true;
          this.message = "Ошибка при сбросе пароля";
        });
    },
    editUser() {
      if (!this.userFullName == null || this.userFullName.trim() === '') {
        this.snackbar = true;
        this.message = "Введите новое имя пользователя";
        return;
      }
      if (!this.userName == null || this.userName.trim() === '') {
        this.snackbar = true;
        this.message = "Введите новый логин";
        return;
      }

      this.newUser = {};
      this.newUser.name = this.userName;
      this.newUser.fullName = this.userFullName;
      this.$http
        .put(`/api/User/${this.currentCategory}`, this.newUser, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.initialize();
          this.dialogCreate = false;
          this.curUser = response.data;
          this.newUser = {
            id: "",
            name: "",
            fullName: "",
          };
          this.snackbar = true;
          this.message = "Сотрудник успешно изменен";
        })
        .catch((error) => {
          alert(error);
          this.snackbar = true;
          this.message = "Ошибка редактирования сотрудника";
        });
    },
    // editItem(item) {
    //   this.$router.push({ path: `/admin/application/${item.id}` });
    // },
    toggleInputMenu() {
      this.isHovered = false;
      this.showInputMenu = !this.showInputMenu;
    },

    selectOption(option) {
      this.selectedOption = option;
      this.toggleInputMenu(); // Вызовите метод для скрытия меню (если это требуется)
    },

    // handleClickOutside(event) {
    //   const inputButton = this.$refs.inputButton;
    //   const inputMenu = this.$refs.inputMenu;

    //   if (
    //     inputButton &&
    //     inputMenu &&
    //     !inputButton.contains(event.target) &&
    //     !inputMenu.contains(event.target)
    //   ) {
    //     this.showInputMenu = false;
    //   }
    // },

    addBorderClass() {
      this.isHovered = true;
    },
    removeBorderClass() {
      this.isHovered = false;
    },
  },

  watch: {
    userName() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("userNameTextarea");
      });
    },
    userFullName() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("userFullNameTextarea");
      });
    },

    userPassword() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("userPasswordTextarea");
      });
    },
  },
};
</script>

<style scoped>
::v-deep .v-toolbar__content {
  align-items: center !important;
}

.btn-container-1 {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 46px;
  align-items: center;
}

.save-btn-second {
  color: #00b800;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin-right: 24px;
}

.v-card__actions {
  align-items: center;
  display: flex;
  padding: 8px;
  justify-content: flex-end;
}

.warning-margin {
  margin-top: 16px;
  margin-bottom: 10px;
}

.warning-title {
  color: var(--gray-1, #333);
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  justify-content: space-between;
  padding: 31px 14px 10px 31px !important;
}

.ActionMenu {
  position: absolute;
  /* bottom: -110px;
  left: -180px; */
  width: 156px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  right: 2%;
}

.ActionMenu-item-1:hover,
.ActionMenu-item-2:hover {
  background-color: rgba(242,
      242,
      246,
      0.5);
  /* изменяем цвет текста при наведении мыши */
}

.ActionMenu-item-1 {
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  border-top-left-radius: 10px;
  /* Закругляем нижний левый угол */
  border-top-right-radius: 10px;
  /* Закругляем нижний правый угол */
  cursor: pointer;
  text-align: start;
}

.ActionMenu-item-2 {
  border-bottom-left-radius: 10px;
  /* Закругляем нижний левый угол */
  border-bottom-right-radius: 10px;
  /* Закругляем нижний правый угол */
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  text-align: start;
  cursor: pointer;
}

.divider-modal {
  background: #e0e0e0;
  height: 1px;
  width: 100%;
  /* Расширяем на всю ширину родительского контейнера */
}

.textarea-pt {
  padding-top: 21px;
}

/* Custom styles for the "Закрыть" button */
.custom-snackbar .v-btn--text.v-btn--success {
  color: #fff;
  /* White text color */
}

::v-deep .v-data-table-header__icon.mdi-arrow-up,
::v-deep .v-data-table-header__icon.mdi-arrow-down {
  display: inline-block;
}

::v-deep .v-data-table-header__icon.mdi-arrow-up::before,
::v-deep .v-data-table-header__icon.mdi-arrow-down::before {
  content: "\F054F";
  /* Unicode код для иконки mdi-arrow-up */
  font-family: "Material Design Icons";
}

/* Значок сортировки по убыванию */
::v-deep .v-data-table-header__icon.mdi-arrow-up.rev::before,
::v-deep .v-data-table-header__icon.mdi-arrow-down.rev::before {
  content: "\F054F";
  /* Unicode код для иконки mdi-arrow-down */
  font-family: "Material Design Icons";
}

.custom-snackbar .v-btn--text.v-btn--success:hover {
  background-color: #357a38;
  /* Darker green background on hover */
}

.role-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  /* Другие стили по вашему усмотрению */
}

.v-col.cols-7 .border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
}

.profile-menu-item:hover {
  /* Здесь вы можете задать стили для hover-эффекта */
  background-color: #f0f0f0;
  /* Пример: изменение цвета фона при наведении */
  color: #333;
  /* Пример: изменение цвета текста при наведении */
  cursor: pointer;
  /* Пример: изменение курсора при наведении */
  /* Другие стили по вашему усмотрению */
}

.border-input {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
  padding: 6px;
}

.border-white {
  border-radius: 8px;
  border: 1px solid var(--02, white);
  background: var(--white, #fff);
  padding: 6px;
  cursor: pointer;
  width: 100%;
}

.divider-modal {
  background: #e0e0e0;
  height: 1px;
  width: 100%;
  /* Расширяем на всю ширину родительского контейнера */
}

.profile-menu-item {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  padding-left: 30px;
  padding-top: 11px;
  padding-bottom: 11px;
  width: 100%;
}

.input-menu {
  position: absolute;
  bottom: 32%;
  left: 30.7%;
  width: 347px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(200, 200, 200, 0.52);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  /* right: -15px; */
}

.select-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  /* margin-left: 15px; */
}

.option-btn {
  margin-bottom: 0px;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-left: 5px;
}

::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 46px;
  align-items: center;
  padding: 0 24px;
}

.red-dot-margin-2 {
  margin-left: -17px;
}

.red-dot-margin-1 {
  text-align: center;
  margin-left: -17px;
}

.btn-contaiener {
  display: flex;
  justify-content: space-evenly;
}

.cancel-btn {
  color: var(--02, #848484);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
}

.delete-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin-right: 24px;
}

.snackbar-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
  cursor: pointer;
}

.save-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #00b800);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  margin-left: 24px;
}

.save-btn-paswd {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #00b800);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  margin-left: 30px;
}

.del-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #b80900);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  margin-left: 30px;
}

.text-limit {
  width: 30ch;
  /* Отображение примерно 10 символов */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--Gray-1, #333);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
}

.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px;
  /* Толщина линии */
  background: #848484;
  /* Цвет линии (в данном случае черный) */
}

.custom-select {
  position: relative;
  width: 350px;
  cursor: pointer;
  /* margin-left: 9px; */
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
}

.select-arrow {
  position: absolute;
  top: 40%;
  right: 30px;
  /* transform: translateY(-50%); */
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  /* Цвет стрелочки */
}

.col-description,
:focus {
  outline: none;
  /* Убираем внешний контур (outline) при фокусе */
  border: none;
  /* Убираем границу (border) при фокусе */
  box-shadow: none;
  /* Убираем тень (box-shadow) при фокусе */
}

.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  margin-bottom: 0px;
}

.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
  /* height: 200px; */
  display: flex;
  align-items: center;
}

.col-content {
  display: flex;
  align-items: center;
}

.role-margin {
  margin-right: 37px;
}

.role-margin-1 {
  margin-right: 30px;
}

.col-content-2 {
  display: flex;
  align-items: center;
  width: fit-content;
}

.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  width: 100%;
  padding-left: 7px;
  min-height: 20px;
  /* Минимальная высота для textarea */
}

::v-deep .v-dialog {
  border-radius: 20px;
  background: #fff;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none;
}

.modal-title {
  color: var(--gray-1, #333);
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.page-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}

.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}

.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}

.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black;
  /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}

.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  /* margin-left: 37px; */
  cursor: pointer;
}

.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}

.search-container {
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-pluss-btn {
  margin-left: 10px;
}

.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search-notfound {
  /* display: flex; */
  margin: 0 auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.search-component {
  /* max-width: 606px; */
  width: 30vw;
  height: 45px;
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: row;
}

.search-component__input {
  border-radius: 100px;
  border: 1px solid var(--gray-5, #e0e0e0);
  width: 100%;
  outline: unset;
  padding: 0 40px 0 15px;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.search-component__icon {
  position: absolute;
  top: 30%;
  right: 55px;
}

#staff-add-button {
  border-radius: 8px;
  background-color: #333;
  color: var(--white, #fff);
  gap: 12px;
  padding: 10px 56px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 45px;
}

::v-deep tbody tr:nth-child(2n) {
  background: rgba(242, 242, 246, 0.5);
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

::v-deep .v-data-table__wrapper>table>thead>tr>th {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  border-bottom: thin solid #848484;
}

@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 100% !important;
  }
}
</style>
